import Vue from 'vue';
import endImg from "@/assets/images/position_end.png";
import statImg from "@/assets/images/position_start.png";
import vueFilter from "@/views/controllers/filter/vuefilter.js";
import carLogo from "@/assets/images/carLogo.png";
const mapSet = {
    data() {
        return {
            lineDriving: {},
            timeer: null
        };
    },
    beforeDestroy() {
        clearTimeout(this.timeer);
    },
    methods: {
        dynamicLine() {
            let newLinePoints = [];
            let len;
            let points = [];
            console.log(this.arratGps)
            for (var i = 0; i < this.arratGps.length; i += 1) {
                points.push(
                    new AMap.LngLat(this.arratGps[i].lng, this.arratGps[i].lat)
                );
            }
            len = points.length;
            let isScale = len > 0 && len % 5 == 0
            console.log(isScale)
            isScale &&
                this.map.setZoomAndCenter(16, [
                    points[len - 1].lng,
                    points[len - 1].lat
                ]);
            newLinePoints = points.slice(len - 2, len); //最后两个点用来画线。
            if(!this.marker){
                this.marker=new AMap.Marker({
                    map: this.map,
                    position: [points[0].lng, points[0].lat],
                    offset: new AMap.Pixel(0, 0),
                    icon: new AMap.Icon({
                        size: new AMap.Size(20, 30),
                        image: carLogo,
                        imageOffset: new AMap.Pixel(0, 0),
                        imageSize: new AMap.Size(20, 30)
                    }),
                    autoRotation: true,
                });
            }
            this.addLine(newLinePoints, true, isScale || len == 1);
        },
        addLine(path, type, isScale) {
            let that = this;
            
            AMap.plugin('AMap.MoveAnimation', function () {
                // 创建折线实例
                type && that.marker && that.marker.stopMove();
                type && that.marker.moveTo([path[path.length - 1].lng, path[path.length - 1].lat], {
                    duration: 2500,
                    autoRotation: true,
                });
            });

            let startLngLat = [path[path.length - 1].lng, path[path.length - 1].lat];
            let endLngLat = [
                that.onlineDetail.extOrderStatus > 40
                    ? that.onlineDetail.itsItemData.tlng
                    : that.onlineDetail.itsItemData.flng,
                that.onlineDetail.extOrderStatus > 40
                    ? that.onlineDetail.itsItemData.tlat
                    : that.onlineDetail.itsItemData.flat
            ];
            that.lineDriving && that.lineDriving.search(startLngLat, endLngLat, function (status, result) {
                //distance，起点到终点的驾车距离，单位：米
                //time,时间预计，单位：秒

                // console.log(result);
                let typeTxt =
                    that.onlineDetail.driverGps.length > 0 ? "距目的地" : "距您";

                if (result.routes[0]) {
                    let distanceVal = result.routes[0].distance * 0.001 || 0;
                    let timeVal =
                        Math.floor(result.routes[0].time / 60) > 0
                            ? Math.floor(result.routes[0].time / 60)
                            : 1;
                    let txt =
                        "<p class='infoWindowData'>" +
                        that.onlineDetail.extOrderStatusTxt +
                        "<br>" +
                        typeTxt +
                        "<span style='color: #e67322;'>" +
                        distanceVal.toFixed(2) +
                        "</span>公里,<span style='color: #e67322;'>" +
                        timeVal +
                        "</span>分钟</p>";
                    that.infoWindow.setContent(txt);
                    
                    that.infoWindow.open(that.map, that.marker.getPosition());
                }
            });

            let polyline = new AMap.Polyline({
                path: path,
                borderWeight: 9, // 线条宽度，默认为 1
                showDir: true,
                strokeColor: "#28F", //线颜色
                strokeWeight: 6, //线宽
                lineJoin: "round",
                lineCap: "round"
                // lineJoin: "round" // 折线拐点连接处样式
            });
            // 将折线添加至地图实例
            that.map.add(polyline);
            that.polyline.push(polyline);

        },
        //预估报价地图设置
        addTime(t, b) {
            var time = new Date(t.replace("-", "/"));
            time.setMinutes(time.getMinutes() + b, time.getSeconds(), 0);
            var d = new Date(time);
            var y = d.getFullYear();
            var m = d.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var da = d.getDate();
            da = da < 10 ? "0" + da : da;
            var h = d.getHours();
            h = h < 10 ? "0" + h : h;
            var minute = d.getMinutes();
            minute = minute < 10 ? "0" + minute : minute;
            var resDate = y + "/" + m + "/" + da + " " + h + ":" + minute;
            return resDate;
        },
        openInfo(distance, time) {
            let info = [];
            let end = this.params.ItemData.departure_time;
            let changetime;
            if (end) {
                var arr = end.split(/[- : /]/);
                let d = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4]);
                changetime = vueFilter.formatDate(d.getTime(), "yyyy/MM/dd hh:mm");
            } else {
                changetime = vueFilter.formatDate(new Date(), "yyyy/MM/dd hh:mm");
            }
            let distanceOk = (distance * 0.001).toFixed(2);
            this.estimateDistance = distanceOk;
            let raveitime = this.addTime(changetime, time / 60);
            info.push(
                "<div><h8 class='infoWindowTime'>预计<span>" +
                raveitime.substring(raveitime.length - 5) +
                "</span>到</h8>"
            );
            info.push(
                "<p class='infoWindowData'>全程<span>" +
                distanceOk +
                "</span>公里   约行驶<span>" +
                Math.round(time / 60) +
                "</span>分钟</p>"
            );
            let Iszoom =
                distanceOk > 200
                    ? 4
                    : distanceOk > 100
                        ? 6
                        : distanceOk > 50
                            ? 8
                            : distanceOk > 15
                                ? 10
                                : distanceOk < 3
                                    ? 14
                                    : distanceOk < 9
                                        ? 12
                                        : 11;
            console.log(Iszoom);
            let infoWindow = new AMap.InfoWindow({
                content: info.join(""),
                offset: new AMap.Pixel(0, -45)
            });
            //两点之间的中间点
            let cflng = Number(this.params.ItemData.flng);
            let ctlng = Number(this.params.ItemData.tlng);
            let cflat = Number(this.params.ItemData.flat);
            let ctlat = Number(this.params.ItemData.tlat);

            let centerGps = new AMap.LngLat(
                cflng + (ctlng - cflng) / 2,
                cflat + (ctlat - cflat) / 2
            );
            // this.map.panTo(new AMap.LngLat(
            //     this.params.ItemData.tlng,
            //     this.params.ItemData.tlat
            // ))
            // this.map.setZoomAndCenter(Iszoom, centerGps);
            let _this = this;
            this.timeer = setTimeout(function () {
                infoWindow.open(_this.map, new AMap.LngLat(
                    _this.params.ItemData.tlng,
                    _this.params.ItemData.tlat
                ));
                console.log(111);
            }, 1050);

            this.map.setFitView(null, false)
            this.map.setZoom(Iszoom)
            // this.map.setFitView([infoWindow,_this.markerList]);
        },
        //报价添加图标
        addyMarck() {
            let that = this;
            let startLngLat = [this.params.ItemData.flng, this.params.ItemData.flat];
            let endLngLat = [this.params.ItemData.tlng, this.params.ItemData.tlat];
            if (this.params.ItemData.tlng) {
                this.planDrivingLine = new AMap.Driving({
                    map: that.map,
                    showTraffic: true, //实时路况信息
                    hideMarkers: true,
                    autoFitView: false,
                    outlineColor: "#357950"
                })

                // 根据起终点经纬度规划驾车导航路线
                this.planDrivingLine.search(startLngLat, endLngLat, function (status, result) {
                    result.routes[0] &&
                        that.openInfo(result.routes[0].distance, result.routes[0].time);
                });
                let saddress, nsddress;
                saddress = new AMap.Text({
                    map: this.map,
                    position: [this.params.ItemData.flng, this.params.ItemData.flat],
                    offset: new AMap.Pixel(24, 18),
                    anchor: "bottom-left",
                    text: this.params.ItemData.start_name
                });
                this.markerList.push(saddress);
                nsddress = new AMap.Text({
                    map: this.map,
                    anchor: "bottom-left",
                    position: [this.params.ItemData.tlng, this.params.ItemData.tlat],
                    offset: new AMap.Pixel(24, 18),
                    text: this.params.ItemData.end_name
                });
                this.markerList.push(nsddress);
                this.markerSet({ slng: this.params.ItemData.flng, slat: this.params.ItemData.flat }, { tlng: this.params.ItemData.tlng, tlat: this.params.ItemData.tlat });
            } else {
                this.map.setZoomAndCenter(12, startLngLat);
            }
        },
        //报价初始化地图
        async initMap() {
            let that = this;
            this.map = new AMap.Map("containerP", {
                resizeEnable: true,
                // expandZoomRange: true,
                // autoFitView: true,
                setZoomAndCenter: [
                    this.params.ItemData.flng,
                    this.params.ItemData.flat,
                ],
                // mapStyle: "amap://styles/whitesmoke",
            });
            if (this.SmartStorage.get("orderDetail") &&
            this.SmartStorage.get("orderDetail").Status == 2) {
                this.mapSetReceivedOrder();
            } else {
                that.addyMarck();
            }

        },
        markerSet(s, t) {
            let sMarker, nMarker;
            sMarker = new AMap.Marker({
                map: this.map,
                position: [s.slng, s.slat],
                offset: new AMap.Pixel(-4, -30),
                icon: new AMap.Icon({
                    image: statImg,
                    imageOffset: new AMap.Pixel(0, 0),
                    imageSize: new AMap.Size(28, 35)
                })
            });
            this.markerList.push(sMarker);
            if(!t.tlng){
                return
            }
            nMarker = new AMap.Marker({
                map: this.map,
                position: [t.tlng, t.tlat],
                offset: new AMap.Pixel(-4, -30),
                icon: new AMap.Icon({
                    image: endImg,
                    imageOffset: new AMap.Pixel(0, 0),
                    imageSize: new AMap.Size(28, 35)
                })
            });
            this.markerList.push(nMarker);
        },
        //订单详情地图设置
        addyMarck2(pathParam) {
            this.markerList = [];
            if (this.onlineDetail.itsItemData.tlng && this.onlineDetail.itsItemData.tlng != 0) {
                this.map.setZoomAndCenter(15, [
                    this.onlineDetail.itsItemData.tlng,
                    this.onlineDetail.itsItemData.tlat
                ]);

                let driverGps = pathParam
                    ? pathParam
                    : [];
                let slng =
                    this.onlineDetail.status === 5 && driverGps.length > 0
                        ? driverGps[0].lng
                        : this.onlineDetail.itsItemData.flng;
                let slat =
                    this.onlineDetail.status === 5 && driverGps.length > 0
                        ? driverGps[0].lat
                        : this.onlineDetail.itsItemData.flat;
                let tlng =
                    this.onlineDetail.status === 5 && driverGps.length > 0
                        ? driverGps[driverGps.length - 1].lng
                        : this.onlineDetail.itsItemData.tlng;
                let tlat =
                    this.onlineDetail.status === 5 && driverGps.length > 0
                        ? driverGps[driverGps.length - 1].lat
                        : this.onlineDetail.itsItemData.tlat;

                this.markerSet({ slng: slng, slat: slat }, { tlng: tlng, tlat: tlat });
                console.log(this.markerList)
                this.map.setFitView(this.markerList);
                this.map.add(this.markerList);
            }
        },
        gpsconvertFrom(datas) {
            console.log(datas);
            let _this = this;
            let data = [];
            let maps = [];
            if (datas.length > 0) {
                datas.forEach(element => {
                    maps.push(new AMap.LngLat(element.lng, element.lat));
                });

                AMap.convertFrom(maps, "baidu", function (status, result) {
                    if (result.info === "ok") {
                        _this.initmaps(result.locations);
                        console.log(result.locations);
                        // _this.arratGps.push(result.locations);
                        _this.arratGps = result.locations;
                    }
                });
            } else {
                this.addyMarck2()
            }
        },
        initmaps(arratGps) {
            let that = this;
            let pathParam = arratGps;
            let lineArr = [];
            console.log(pathParam);
            this.addyMarck2(pathParam);
            if (pathParam && pathParam.length > 0) {
                for (let j = 0; j < pathParam.length; j += 1) {
                    pathParam[j].lng != 0 &&
                        lineArr.push(new AMap.LngLat(pathParam[j].lng, pathParam[j].lat));
                }
                console.log(lineArr);
                if (that.onlineDetail.status === 5) {
                    let abbr = [];
                    lineArr.forEach((element, index) => {
                        if (
                            (index != 0 && index % 60 == 0) ||
                            index == pathParam.length - 1
                        ) {
                            index == pathParam.length - 1 && abbr.push(element);
                            let polyline2 = new AMap.Polyline({
                                path: abbr,
                                // showDir: true,
                                strokeColor: "#28F", //线颜色
                                strokeWeight: 6, //线宽
                                lineJoin: "round",
                                lineCap: "round"
                            });
                            // 将折线添加至地图实例
                            that.map.add(polyline2);
                            abbr = [];
                            abbr.push(lineArr[index - 1]);
                        }
                        abbr.push(element);
                    });
                    this.map.setFitView();
                    this.map.panBy(20, -150);
                } else {
                    this.marker = new AMap.Marker({
                        map: this.map,
                        position: [
                            pathParam[pathParam.length - 1].lng,
                            pathParam[pathParam.length - 1].lat
                        ],
                        offset: new AMap.Pixel(0, 0),
                        icon: new AMap.Icon({
                            size: new AMap.Size(20, 30),
                            image: carLogo,
                            imageOffset: new AMap.Pixel(0, 0),
                            imageSize: new AMap.Size(20, 30)
                        }),
                        autoRotation: true,
                    });
                    this.addLine(lineArr, false);
                    this.map.setZoomAndCenter(18, [
                        pathParam[pathParam.length - 1].lng,
                        pathParam[pathParam.length - 1].lat
                    ]);
                    this.map.panBy(20, -100);
                }
            }
        },
    }
}
Vue.mixin(mapSet)

export default mapSet;